import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;

  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const Input = styled.input`
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const Divider = styled.div`
  display: none;
  height: 5px;
  width: 100%;
  background-color: green;
  margin: 0px 0;
  border-radius: 10px;

  @media (max-width: 600px) {
    display: block;
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #003f8a;
    transform: translateY(0);
  }
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
`;
const Select = styled.select`
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;
const BillingForm = ({ onBillingSubmit }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    onBillingSubmit(data);
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <SectionTitle>Please enter the Zone Number</SectionTitle>
      
      {/* Zone Number Section */}
      <Input placeholder="Zone Number" {...register("zoneNumber", { required: true })} />

      <SectionTitle>Select time</SectionTitle>
      <Select name="time" {...register("time", { required: true })}>
        <option value="">Select time (hours)</option>
        {[...Array(24)].map((_, index) => (
          <option key={index + 1} value={index + 1}>
            {index + 1} {index + 1 === 1 ? 'hour' : 'hours'}
          </option>
        ))}
      </Select>

      {/* Plate Number Section */}
      <SectionTitle>Enter plate number</SectionTitle>
      <Input type="text" name="plateNumber" placeholder="Enter the vehicle plate number" required />
      
      {/* Divider visible only on mobile */}
      <Divider />
      <SectionTitle>Billing Information</SectionTitle>
      {/* Other Billing Information */}
      <Input placeholder="First Name" {...register("fullName1", { required: true })} />
      <Input placeholder="Last Name" {...register("fullName2", { required: true })} />
      <Input placeholder="Address" {...register("address", { required: true })} />
      <Input placeholder="City" {...register("city", { required: true })} />
      <Input placeholder="Zip Code" {...register("zipCode", { required: true })} />
      <Input placeholder="State" {...register("state", { required: true })} />
      <Input placeholder="Country" {...register("country", { required: true })} />
      
      <SubmitButton type="submit">Save & Continue</SubmitButton>
    </FormContainer>
  );
};

export default BillingForm;
