import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import homeLogo from "../assets/home-logo.png"; // Example logos
import paymentLogo from "../assets/payment-logo.svg"; // Example logos

const HeaderContainer = styled.div`
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
`;

const Logo = styled.img`
  width: 220px;
  height: auto;
`;

const Header = () => {
  const location = useLocation();

  // Determine the logo based on the current route
  let logoSrc;
  if (location.pathname === "/park-mobile") {
    logoSrc = paymentLogo;
  } else {
    logoSrc = homeLogo;
  }

  return (
    <HeaderContainer>
      <Logo src={logoSrc} alt="Dynamic Logo" />
    </HeaderContainer>
  );
};

export default Header;
