import React from "react";

const ParkingCost = ({ hours }) => {
  const formattedPrice = (hours * 3.50).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <p>Total Cost: {formattedPrice}</p>
    </div>
  );
};

export default ParkingCost;
