import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PaymentPage from "./pages/PaymentPage";
import GlobalStyles from "./styles/GlobalStyles";
import Header from "./components/Header";

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Header />
      <Routes>
        
        <Route path="/park-mobile" element={<PaymentPage />} />
      </Routes>
    </Router>
  );
}

export default App;
