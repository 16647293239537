import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useForm } from "react-hook-form";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Input = styled.input`
  padding: 15px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #003f8a;
    transform: translateY(0);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;

const CardDetailsForm = ({ onSubmit, billingId }) => {
  // eslint-disable-next-line
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({ mode: "onTouched" });
  const [loading, setLoading] = useState(false);

  const handleCardNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    const limitedValue = value.slice(0, 16); // Limit to 16 digits
    const formattedValue = limitedValue
      .replace(/(\d{4})(?=\d)/g, "$1 ") // Group by 4 digits and add a space
      .trim();
    setValue("cardNumber", formattedValue); // Set formatted value to the form
  };
  const handleExpiryDateChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // Remove non-digits
    if (value.length >= 2) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`; // Format as MM/YY
    }
    setValue("expiryDate", value); // Set formatted value
  };

  const handleCardSubmit = (data) => {
    setLoading(true);
    // Remove all spaces before sending the card number to the backend
    const cleanCardNumber = data.cardNumber.replace(/\s/g, "");

    onSubmit({
      billingId,
      cardNumber: cleanCardNumber,
      expiryDate: data.expiryDate,
      cvv: data.cvv,
    });

    // Simulate loading time
    setTimeout(() => setLoading(false), 2000);
  };

  return (
    <FormContainer onSubmit={handleSubmit(handleCardSubmit)}>
      <Input
        placeholder="Card Number"
        {...register("cardNumber", {
          required: "Card number is required",
          validate: {
            isValidCardNumber: value => /^\d{15,16}$/.test(value.replace(/\s/g, "")) || "Invalid card number. Must be 15 or 16 digits",
          }
        })}
        onChange={handleCardNumberChange} // Add onChange handler
        maxLength={19} // Maximum length to accommodate spaces (16 digits + 3 spaces)
      />
      {errors.cardNumber && <ErrorMessage>{errors.cardNumber.message}</ErrorMessage>}

      <Input
        placeholder="Expiry Date (MM/YY)"
        {...register("expiryDate", {
          required: "Expiry date is required",
          pattern: {
            value: /^(0[1-9]|1[0-2])\/\d{2}$/,
            message: "Invalid expiry date. Format must be MM/YY",
          },
        })}
        onChange={handleExpiryDateChange}
        maxLength={5} // Allow 5 characters (MM/YY)
      />
      {errors.expiryDate && <ErrorMessage>{errors.expiryDate.message}</ErrorMessage>}


      <Input
        placeholder="CVV"
        type="password"
        {...register("cvv", {
          required: "CVV is required",
          pattern: {
            value: /^\d{3,4}$/,
            message: "Invalid CVV. Must be 3 or 4 digits",
            
          },
          
        })}
        maxLength={4}
      />
      {errors.cvv && <ErrorMessage>{errors.cvv.message}</ErrorMessage>}

      <SubmitButton type="submit" disabled={loading}>
        {loading ? <Spinner /> : "Save Card Details"}
      </SubmitButton>
    </FormContainer>
  );
};

export default CardDetailsForm;
