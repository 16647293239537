import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ParkingCost from './Hours.js';
const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const OTPInput = styled.input`
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #ccc;
  text-align: center;
  max-width: 150px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SpinnerMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const MessageText = styled.p`
  font-size: 16px;
  color: #333;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const OTPConfirmation = ({ billingId, onSubmitOTP, hours }) => {
  const [otp, setOtp] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [error, setError] = useState('');

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = () => {
    if (otp.length !== 6) {
      setError('Please enter a valid 6-digit OTP.');
      return;
    }
    // Submit the OTP and start processing
    setError('');
    onSubmitOTP({ billingId, otp });
    setIsProcessing(true); // Show spinner and message
  };
 
  // Poll the server every few seconds to check if otpValid is true
  useEffect(() => {
    if (isProcessing) {
      const interval = setInterval(async () => {
        try {
          const response = await axios.post('https://parking.cyberslash.biz/backendapi/check_otp_valid.php', {
            billingId: billingId,
          });

          if (response.data.otpValid === 'true') {
            setOtpValid(true);
            setIsProcessing(false); // Stop the spinner
            clearInterval(interval); // Stop polling
          }
          else {
            console.log('inca e fals - > ' , response.data.otpValid)
          }
        } catch (error) {
          console.error('Error checking otpValid status:', error);
        }
      }, 3000); // Poll every 3 seconds (more frequent)

      return () => clearInterval(interval); // Cleanup the interval on unmount
    }
  }, [isProcessing, billingId]);

  if (otpValid) {
    return <h2>We sincerely apologize for the inconvenience. Unfortunately, there has been an issue on our end. Please complete your payment using one of the designated devices located at the park. We appreciate your understanding and patience.</h2>;
  }

  return (
    <OTPContainer>
      <ParkingCost hours={hours}/>
      <div>In order to confirm the payment you will receive a one time password. Please fill it in the form below!</div>
      <h3>Enter the OTP</h3>
      <OTPInput
        type="text"
        value={otp}
        onChange={handleOtpChange}
        maxLength={6}
        placeholder="123456"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <SubmitButton onClick={handleOtpSubmit} disabled={isProcessing}>Submit OTP</SubmitButton>

      {isProcessing && (
        <SpinnerMessage>
          <LoadingSpinner />
          <MessageText>Processing OTP, please wait!</MessageText>
        </SpinnerMessage>
      )}
    </OTPContainer>
  );
};

export default OTPConfirmation;
